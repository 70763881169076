<template>
  <div class="zmail-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="zmail-context-menu__group">
      <component
        :is="item.attrs ? 'a' : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="zmail-context-menu__item standart-title"
        :class="{ [`zmail-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import { ZmailTariff } from '@/models/BillMgr/ZmailTariff';
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import Instruction from '@/layouts/Zmail/components/Instruction';
import zmailChangePassword from '@/layouts/Zmail/mixins/zmailChangePassword.js';
import ZmailChangePassword from '@/layouts/Zmail/components/ZmailChangePassword.vue';
export default {
  name: 'ZmailContextMenu',
  mixins: [showErrorModal, zmailChangePassword],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof ZmailTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // menuOther: [
      //   // {
      //   //   key: 'createBackup',
      //   //   disabled: true,
      //   // },
      //   {
      //     key: this.tariff.status.code === 1 ? 'removeOrder' : 'removeHosting',
      //     color: 'del',
      //     disabled: this.tariff.status.code !== 1,
      //     handler: this.tariff.status.code === 1 ? this.deleteFromBasket : null,
      //   },
      // ],
    };
  },
  computed: {
    serverState() {
      return this.tariff.status;
    },
    tools() {
      return this.$store.state.moduleZmail.tools;
    },
    menu() {
      const status = this.serverState.code;
      const { gotoserver } = this.tools;
      const arr = [[]];
      if (gotoserver) {
        arr[0].push(
          {
            key: 'serverPanel',
            tool: gotoserver,
            disabled: !gotoserver.isEnable(this.tariff),
          },
          {
            key: 'changePassword',
            handler: this.zmailChangePassword,
            disabled: ![2].includes(status),
          }
          // {
          //   key: 'instruction',
          //   handler: 'func',
          // }
        );
      }
      // arr[0].push(...this.menuOther);
      return arr;
    },
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.key === 'instruction') return this.openInstruction();
      // if (item.handler === 'changePassword') this.zmailChangePassword(item);
      if (item.handler && item.handler === 'func') this.askSure(item.key);
      else if (item.handler && typeof item.handler === 'function') item.handler(item);
      else if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      } else if (!item.attrs) alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('moduleZmail/fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        })
        .catch(e => {
          this.showError(e);
        });
    },
    zmailChangePassword() {
      let modal = null;
      this.$modals.open({
        name: 'ZmailChangePassword',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      const params = {
        func: 'service.changepassword',
        elid: this.tariff.id,
        elname: this.tariff.name,
        // out: 'none',
        show_metadata: 'on',
      };
      this.$store
        .dispatch('moduleZmail/changePassword', params)
        .then(data => {
          // console.log(data);
          let self = null;
          let hasChanges = false;
          this.$modals.close();
          this.$modals.open({
            name: 'SettingsModal',
            size: 'big',
            component: ZmailChangePassword,
            props: data,
            on: {
              ready: () => {
                self.footer.confirm.props.disabled = false;
              },
              notready: () => {
                self.footer.confirm.props.disabled = true;
              },
              change: val => {
                // console.log(val);
                hasChanges = val;
              },
              submit: data => {
                const payload = { data };
                if (props.user) payload.id = props.user.id;
                Vue.set(self.props, 'formLoading', true);
                this.updateUser(payload)
                  .then(() => this.$modals.close())
                  .catch(e => {
                    // console.log(e);
                    this.showError(e);
                    Vue.set(self.props, 'formLoading', false);
                    Vue.set(self.props, 'doSubmit', false);
                    if (e.type === 'value' && e.object) {
                      Vue.set(self.props, 'errors', {
                        [e.object]: { msg: e.msg, value: data[e.object] },
                      });
                    }
                  });
              },
            },
            onOpen: instance => (self = instance),
            onClose: () => (self = null),
            footer: {
              confirm: {
                props: { title: this.$t('modal.sure.ok'), disabled: true },
                on: {
                  click: () => {
                    {
                      Vue.set(self.footer.confirm.props, 'loading', true);
                      let res = '';
                      let time = Date.now();
                      this.$store
                        .dispatch('moduleZmail/sendChangePassword', {
                          ...hasChanges,
                          elid: this.tariff.id,
                        })
                        .then(data => {
                          // console.log(data);
                          res = 'change';
                          //
                        })
                        .catch(e => {
                          res = 'fail';
                          // console.log(e);
                          this.showError(e);
                        })
                        .finally(() => {
                          Vue.set(self.footer.confirm.props, 'loading', false);
                          // console.log(res);
                          // this.$modals.close();
                          // this.showSuccessModal(res);
                          const delay = Date.now() - time < 1000 ? 1000 : 0;
                          setTimeout(() => {
                            self.component = null;
                            self.closable = true;
                            self.text = this.$t(`modal.sure.${res}`);
                            self.footer = {
                              centered: true,
                              confirm: {
                                props: { title: this.$t('modal.sure.ok') },
                                on: { click: () => this.$modals.close() },
                              },
                            };
                          }, delay);
                        });
                    }
                  },
                },
              },
              // cancel: {
              //   on: { click: () => this.$modals.dismiss() },
              // },
            },
          });
        })
        .catch(e => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'closable', true);
          this.showError(e);
        })
        .finally(() => this.$modals.close);
    },
    deleteFromBasket() {
      let modal = null;
      this.$modals.open({
        name: 'RemoveBasketResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleBasket/removeFromBasket', this.tariff.id)
        .then(() => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'text', this.$t('modal.removeFromBasket.success'));
          Vue.set(modal, 'closable', true);
          this.$store.dispatch('moduleZmail/updateList');
          if (this.$route.params.id && this.$route.params.id == this.tariff.id) {
            this.$router.push({ name: 'zmailMain' });
          }
        })
        .catch(e => this.showError(e));
    },
    openInstruction(props = {}) {
      let self = null;
      this.$modals.open({
        name: 'Instruction',
        size: 'large',
        component: Instruction,
        closeOnBackdrop: false,
        props: {
          tariff: this.tariff,
        },
        // title: this.$t('new_ticket'),
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          self = null;
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "change": "изменить",
      "stop": "остановить",
      "panel": "перейти в панель",
      "history": "история",
      "serverPanel":"Перейти в веб. интерфейс",
      "changePassword":"Изменить пароль",

      "instruction": "Инструкция",

      "removeHosting": "удалить ",
      "removeOrder": "удалить заказ"
    },
    "modal": {
      "sure": {
        "ok": "ОК",
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "change": "Пароль успешно изменен.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.zmail-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.zmail-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
